/* ----------------------------------------------------------------------- */
@-webkit-keyframes moveToLeft {
    from {
        -webkit-transform: translateX(0px) rotateY(0deg) rotateZ(0deg);
        transform: translateX(0px) rotateY(0deg) rotateZ(0deg);
    }
    to {
        -webkit-transform: translateX(-100px) rotateY(-8deg) rotateZ(-4deg);
        transform: translateX(-100px) rotateY(-8deg) rotateZ(-4deg);
    }
}

@keyframes moveToLeft {
    from {
        -webkit-transform: translateX(0px) rotateY(0deg) rotateZ(0deg);
        transform: translateX(0px) rotateY(0deg) rotateZ(0deg);
    }
    to {
        -webkit-transform: translateX(-100px) rotateY(-8deg) rotateZ(-4deg);
        transform: translateX(-100px) rotateY(-8deg) rotateZ(-4deg);
    }
}

@-webkit-keyframes moveToRight {
    from {
        -webkit-transform: translateX(-100px) rotateY(-8deg) rotateZ(-4deg);
        transform: translateX(-100px) rotateY(-8deg) rotateZ(-4deg);
    }
    to {
        -webkit-transform: translateX(100px) rotateY(8deg) rotateZ(4deg);
        transform: translateX(100px) rotateY(8deg) rotateZ(4deg);
    }
}

@keyframes moveToRight {
    from {
        -webkit-transform: translateX(-100px) rotateY(-8deg) rotateZ(-4deg);
        transform: translateX(-100px) rotateY(-8deg) rotateZ(-4deg);
    }
    to {
        -webkit-transform: translateX(100px) rotateY(8deg) rotateZ(4deg);
        transform: translateX(100px) rotateY(8deg) rotateZ(4deg);
    }
}

@-webkit-keyframes moveToCenter {
    from {
        -webkit-transform: translateX(100px) rotateY(8deg) rotateZ(4deg);
        transform: translateX(100px) rotateY(8deg) rotateZ(4deg);
    }
    to {
        -webkit-transform: translateX(0px) rotateY(0deg) rotateZ(0deg);
        transform: translateX(0px) rotateY(0deg) rotateZ(0deg);
    }
}

@keyframes moveToCenter {
    from {
        -webkit-transform: translateX(100px) rotateY(8deg) rotateZ(4deg);
        transform: translateX(100px) rotateY(8deg) rotateZ(4deg);
    }
    to {
        -webkit-transform: translateX(0px) rotateY(0deg) rotateZ(0deg);
        transform: translateX(0px) rotateY(0deg) rotateZ(0deg);
    }
}

/* ----------------------------------------------------------------------- */
.animate-left {
    -webkit-animation: moveToLeft 0.5s ease-in-out forwards;
    animation: moveToLeft 0.5s ease-in-out forwards;
}

.animate-right {
    -webkit-animation: moveToRight 0.5s ease-in-out forwards;
    animation: moveToRight 0.5s ease-in-out forwards;
}

.animate-center {
    -webkit-animation: moveToCenter 0.5s ease-in-out forwards;
    animation: moveToCenter 0.5s ease-in-out forwards;
}

@keyframes flipRight {
    0% {
      transform: perspective(600px) rotateY(0);
    }
    100% {
      transform: perspective(600px) rotateY(-180deg);
    }
  }

  @keyframes flipLeft {
    0% {
      transform: perspective(600px) rotateY(0);
    }
    100% {
      transform: perspective(600px) rotateY(180deg);
    }
  }

  .flip-right {
    animation: flipRight 0.5s forwards;
  }

  .flip-left {
    animation: flipLeft 0.5s forwards;
  }



.next-card {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    background-size: cover;
    background-position: center;
    backface-visibility: hidden;
    transform: perspective(600px) rotateY(180deg);
}


.static-card {
    border-style: solid;
    border-width: 2px;
    border-color: #57646D;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    background-size: cover;
    z-index: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
}

.static-card, .card-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
}

.card-wrapper {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-will-change: transform, opacity;
    will-change: transform, opacity;
    box-shadow: 0 4px 8px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    z-index: 1;
}

/* ----------------------------------------------------------------------- */
.card {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #2c3e50;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    border-style: solid;
    border-width: 2px;
    border-color: #57646D;
    box-shadow: 0 4px 2px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ecf0f1;
    /* font-family: 'Arial', sans-serif; */
    cursor: pointer;
    user-select: none;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-size: cover;
    background-position: center;
    overflow: hidden;
}

.red {
    box-shadow: 0px 0px 20px 4px #F56780;
}

.green {
    box-shadow: 0px 0px 20px 4px #2EC17B;
}

.hidden {
    transition: -webkit-transform 0.25s ease, opacity 0.5s ease-in-out;
    transition: transform 0.25s ease, opacity 0.5s ease-in-out;
    transition: transform 0.25s ease, opacity 0.5s ease-in-out, -webkit-transform 0.25s ease;
    opacity: 0;
}

.overlay {
    position: absolute;
    top: -7.5%;
    width: 53vh;
    height: 25%;
    background: #13191F;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: 0;
    transition: transform 0.05s ease-in-out, -webkit-transform 0.05s ease-in-out;
    transition: -webkit-transform 0.05s ease-in-out;
}

.overlay .text-answer {
    color: #b7d5df;
    font-family: 'Kazimir';
    font-style: normal;
    font-size: calc(6px + 20 * (100vh - 320px) / 680);
    font-weight: 600;
    line-height: 107%;
    z-index: 1;
    width: 65%;
    margin: 25px 50px 0 50px;
}

.red-svg, .green-svg {
    position: absolute;
    top: -1px;
    width: 125px;
    height: 60px;
    border-radius: 0 0 120px 120px;
    -webkit-border-radius: 0 0 120px 120px;
    box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.5);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

.red-svg {
    background-image: url('./assets/red_icon.png');
}

.green-svg {
    background-image: url('./assets/green_icon.png');
}

/* ----------------------------------------------------------------------- */
.red-buttons, .effects-wrapper-red {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.button-wrapper {
    position: relative;
    display: inline-block;
    width: 85%;
    height: 60px;
}

.button-with-illustration, .button-without-illustration {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 90px;
    -webkit-border-radius: 90px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
}

.button-with-illustration {
    background: -webkit-radial-gradient(circle, #414D54 0%, #27353B 100%);
    background: radial-gradient(circle, #414D54 0%, #27353B 100%);
}

.button-without-illustration {
    background: -webkit-radial-gradient(circle, #313D43 0%, #131F25 100%);
    background: radial-gradient(circle, #313D43 0%, #131F25 100%);
}

.button-with-illustration:hover {
    background: -webkit-radial-gradient(circle, #556168 0%, #313F45 100%);
    background: radial-gradient(circle, #556168 0%, #313F45 100%);
}

.button-with-illustration:active {
    background: -webkit-radial-gradient(circle, #2D3940 0%, #1D2B31 100%);
    background: radial-gradient(circle, #2D3940 0%, #1D2B31 100%);
}

.button-without-illustration:hover {
    background: -webkit-radial-gradient(circle, #455157 0%, #1D292F 100%);
    background: radial-gradient(circle, #455157 0%, #1D292F 100%);
}

.button-without-illustration:active {
    background: -webkit-radial-gradient(circle, #1D292F 0%, #09151B 100%);
    background: radial-gradient(circle, #1D292F 0%, #09151B 100%);
}

.button-with-illustration h4, .button-without-illustration h4 {
    font-family: 'Graphik';
    font-weight: 600;
    font-style: normal;
    text-align: center;
    line-height: 105%;
    font-size: calc(6px + 20*(100vh - 320px) / 680);
}

.button-with-illustration h4 {
    background: -webkit-linear-gradient(to right, #FFEECC, #CB8702);
    background: linear-gradient(to right, #FFEECC, #CB8702);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.button-without-illustration h4 {
    color: rgba(223, 232, 237, .84);
}

.button-border, .button-border-gray {
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: 0;
    border-radius: 90px;
    -webkit-border-radius: 90px;
    z-index: 0;
    box-shadow: 0px 1px 0px 0.5px #000000;
}

.button-border {
    background: -webkit-linear-gradient(to bottom, #DED8B9, rgba(255, 255, 255, .5));
    background: linear-gradient(to bottom, #DED8B9, rgba(0, 0, 0, .5));
    bottom: -1px;
    border-width: .5px;
    box-shadow: 0px 1px 0px 1px #000000;
}

.button-border-gray {
    background: -webkit-linear-gradient(to top, #000000, rgba(255, 255, 255, 0.5));
    background: linear-gradient(to top, #000000, rgba(255, 255, 255, 0.5));
    top: 0px;
    bottom: -2px;
    left: -2px;
    right: -2px;
}

.button-illustration {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 90px;
    -webkit-border-radius: 90px;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    background: url('./assets/button_illustration.svg') no-repeat center center;
    background-size: cover;
}

.button-flickers {
    position: absolute;
    width: 90%;
    height: 81px;
    background: url('./assets/flickers.png') no-repeat center center;
    background-size: cover;
}

.button-glow {
    position: absolute;
    width: 85%;
    height: 60px;
    border-radius: 90px;
    -webkit-border-radius: 90px;
    box-shadow: 0 0 60px 10px rgba(255, 232, 152, 0.75);
}

/* ----------------------------------------------------------------------- */
.card-blur {
    position: absolute;
    width: 100%;
    height: 100%;
    filter: blur(3px);
    background-size: cover;
    background-position: center;
}

.card-darkness {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(16, 24, 28, .25);
}

/* Media Queries */
@media (max-aspect-ratio: 29 / 50) {
    .overlay {
        width: 88vw;
        height: 27%;
    }

    .overlay .text-answer {
        margin: 25px 25px 0 25px;
    }

    .static-card, .card-wrapper, .card {
        border-radius: 22.5px;
        -webkit-border-radius: 22.5px
    }
}

@media only screen and (max-width: 375px) and (orientation: portrait) {
    .overlay {
        width: 88vw;
        height: 27%;
    }

    .overlay .text-answer {
        margin: 25px 25px 0 25px;
    }

    .static-card, .card-wrapper, .card {
        border-radius: 22.5px;
        -webkit-border-radius: 22.5px;
    }
}
