html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Kazimir';
}

body {
  overflow: hidden;
}

h4, h3, h2, h1 {
  margin-block-start: 0;
  margin-block-end: 0;
  user-select: none;
}

@font-face {
  font-family: 'Graphik Regular';
  src: url('./assets/fonts/Graphik-Regular-Web.eot');
  src:  url('./assets/fonts/Graphik-Regular-Web.eot#iefix') format('embedded-opentype'),
        url('./assets/fonts/Graphik-Regular-Web.woff2') format('woff2'),
        url('./assets/fonts/Graphik-Regular-Web.woff') format('woff'),
        url('./assets/fonts/Graphik-Regular-Web.ttf') format('truetype');

  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik';
  src: url('./assets/fonts/Graphik-Medium-Web.eot');
  src:  url('./assets/fonts/Graphik-Medium-Web.eot#iefix') format('embedded-opentype'),
        url('./assets/fonts/Graphik-Medium-Web.woff2') format('woff2'),
        url('./assets/fonts/graphik_lc_600.woff') format('woff'),
        url('./assets/fonts/graphik_lc_600.ttf') format('truetype');

  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik Semibold';
  src: url('./assets/fonts/Graphik-Semibold-Web.eot');
  src:   url('./assets/fonts/Graphik-Semibold-Web.eot#iefix') format('embedded-opentype'),
          url('./assets/fonts/Graphik-Semibold-Web.woff2') format('woff2'),
          url('./assets/fonts/Graphik-Semibold-Web.woff') format('woff'),
          url('./assets/fonts/Graphik-Semibold-Web.ttf') format('truetype');

  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik Bold';
  src: url('./assets/fonts/Graphik-Bold-Web.eot');
  src:  url('./assets/fonts/Graphik-Bold-Web.eot#iefix') format('embedded-opentype'),
        url('./assets/fonts/Graphik-Bold-Web.woff2') format('woff2'),
        url('./assets/fonts/Graphik-Bold-Web.woff') format('woff'),
        url('./assets/fonts/Graphik-Bold-Web.ttf') format('truetype');

  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Kazimir';
  src: url('./assets/fonts/Kazimir-BlackItalic.eot');
  src: local('Kazimir Black Italic'), local('Kazimir-BlackItalic'),
      url('./assets/fonts/Kazimir-BlackItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Kazimir-BlackItalic.woff2') format('woff2'),
      url('./assets/fonts/Kazimir-BlackItalic.woff') format('woff'),
      url('./assets/fonts/Kazimir-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Kazimir';
  src: url('./assets/fonts/Kazimir-BoldItalic.eot');
  src: local('Kazimir Bold Italic'), local('Kazimir-BoldItalic'),
      url('./assets/fonts/Kazimir-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Kazimir-BoldItalic.woff2') format('woff2'),
      url('./assets/fonts/Kazimir-BoldItalic.woff') format('woff'),
      url('./assets/fonts/Kazimir-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Kazimir';
  src: url('./assets/fonts/Kazimir-Black.eot');
  src: local('Kazimir Black'), local('Kazimir-Black'),
      url('./assets/fonts/Kazimir-Black.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Kazimir-Black.woff2') format('woff2'),
      url('./assets/fonts/Kazimir-Black.woff') format('woff'),
      url('./assets/fonts/Kazimir-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Kazimir';
  src: url('./assets/fonts/Kazimir-MediumItalic.eot');
  src: local('Kazimir Medium Italic'), local('Kazimir-MediumItalic'),
      url('./assets/fonts/Kazimir-MediumItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Kazimir-MediumItalic.woff2') format('woff2'),
      url('./assets/fonts/Kazimir-MediumItalic.woff') format('woff'),
      url('./assets/fonts/Kazimir-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Kazimir';
  src: url('./assets/fonts/Kazimir-Light.eot');
  src: local('Kazimir Light'), local('Kazimir-Light'),
      url('./assets/fonts/Kazimir-Light.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Kazimir-Light.woff2') format('woff2'),
      url('./assets/fonts/Kazimir-Light.woff') format('woff'),
      url('./assets/fonts/Kazimir-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Kazimir';
  src: url('./assets/fonts/Kazimir-Italic.eot');
  src: local('Kazimir Italic'), local('Kazimir-Italic'),
      url('./assets/fonts/Kazimir-Italic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Kazimir-Italic.woff2') format('woff2'),
      url('./assets/fonts/Kazimir-Italic.woff') format('woff'),
      url('./assets/fonts/Kazimir-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Kazimir';
  src: url('./assets/fonts/Kazimir-Regular.eot');
  src: local('Kazimir Regular'), local('Kazimir-Regular'),
      url('./assets/fonts/Kazimir-Regular.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Kazimir-Regular.woff2') format('woff2'),
      url('./assets/fonts/Kazimir-Regular.woff') format('woff'),
      url('./assets/fonts/Kazimir-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Kazimir';
  src: url('./assets/fonts/Kazimir-Medium.eot');
  src: local('Kazimir Medium'), local('Kazimir-Medium'),
      url('./assets/fonts/Kazimir-Medium.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Kazimir-Medium.woff2') format('woff2'),
      url('./assets/fonts/Kazimir-Medium.woff') format('woff'),
      url('./assets/fonts/Kazimir-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Kazimir';
  src: url('./assets/fonts/Kazimir-LightItalic.eot');
  src: local('Kazimir Light Italic'), local('Kazimir-LightItalic'),
      url('./assets/fonts/Kazimir-LightItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Kazimir-LightItalic.woff2') format('woff2'),
      url('./assets/fonts/Kazimir-LightItalic.woff') format('woff'),
      url('./assets/fonts/Kazimir-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Kazimir';
  src: url('./assets/fonts/Kazimir-Bold.eot');
  src: local('Kazimir Bold'), local('Kazimir-Bold'),
      url('./assets/fonts/Kazimir-Bold.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Kazimir-Bold.woff2') format('woff2'),
      url('./assets/fonts/Kazimir-Bold.woff') format('woff'),
      url('./assets/fonts/Kazimir-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('./assets/fonts/Graphik-Thin.eot');
  src: local('Graphik Thin'), local('Graphik-Thin'),
      url('./assets/fonts/Graphik-Thin.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Graphik-Thin.woff2') format('woff2'),
      url('./assets/fonts/Graphik-Thin.woff') format('woff'),
      url('./assets/fonts/Graphik-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('./assets/fonts/Graphik-ThinItalic.eot');
  src: local('Graphik Thin Italic'), local('Graphik-ThinItalic'),
      url('./assets/fonts/Graphik-ThinItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Graphik-ThinItalic.woff2') format('woff2'),
      url('./assets/fonts/Graphik-ThinItalic.woff') format('woff'),
      url('./assets/fonts/Graphik-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik Super';
  src: url('./assets/fonts/Graphik-SuperItalic.eot');
  src: local('Graphik Super Italic'), local('Graphik-SuperItalic'),
      url('./assets/fonts/Graphik-SuperItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Graphik-SuperItalic.woff2') format('woff2'),
      url('./assets/fonts/Graphik-SuperItalic.woff') format('woff'),
      url('./assets/fonts/Graphik-SuperItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik';
  src: url('./assets/fonts/Graphik-Medium.eot');
  src: local('Graphik Medium'), local('Graphik-Medium'),
      url('./assets/fonts/Graphik-Medium.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Graphik-Medium.woff2') format('woff2'),
      url('./assets/fonts/Graphik-Medium.woff') format('woff'),
      url('./assets/fonts/graphik_lc_500.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('./assets/fonts/Graphik-Black.eot');
  src: local('Graphik Black'), local('Graphik-Black'),
      url('./assets/fonts/Graphik-Black.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Graphik-Black.woff2') format('woff2'),
      url('./assets/fonts/Graphik-Black.woff') format('woff'),
      url('./assets/fonts/Graphik-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik Super';
  src: url('./assets/fonts/Graphik-Super.eot');
  src: local('Graphik Super'), local('Graphik-Super'),
      url('./assets/fonts/Graphik-Super.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Graphik-Super.woff2') format('woff2'),
      url('./assets/fonts/Graphik-Super.woff') format('woff'),
      url('./assets/fonts/Graphik-Super.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('./assets/fonts/Graphik-ExtralightItalic.eot');
  src: local('Graphik Extralight Italic'), local('Graphik-ExtralightItalic'),
      url('./assets/fonts/Graphik-ExtralightItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Graphik-ExtralightItalic.woff2') format('woff2'),
      url('./assets/fonts/Graphik-ExtralightItalic.woff') format('woff'),
      url('./assets/fonts/Graphik-ExtralightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik';
  src: url('./assets/fonts/Graphik-BlackItalic.eot');
  src: local('Graphik Black Italic'), local('Graphik-BlackItalic'),
      url('./assets/fonts/Graphik-BlackItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Graphik-BlackItalic.woff2') format('woff2'),
      url('./assets/fonts/Graphik-BlackItalic.woff') format('woff'),
      url('./assets/fonts/Graphik-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik';
  src: url('./assets/fonts/Graphik-RegularItalic.eot');
  src: local('Graphik Regular Italic'), local('Graphik-RegularItalic'),
      url('./assets/fonts/Graphik-RegularItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Graphik-RegularItalic.woff2') format('woff2'),
      url('./assets/fonts/Graphik-RegularItalic.woff') format('woff'),
      url('./assets/fonts/Graphik-RegularItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik';
  src: url('./assets/fonts/Graphik-Light.eot');
  src: local('Graphik Light'), local('Graphik-Light'),
      url('./assets/fonts/Graphik-Light.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Graphik-Light.woff2') format('woff2'),
      url('./assets/fonts/Graphik-Light.woff') format('woff'),
      url('./assets/fonts/Graphik-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('./assets/fonts/Graphik-Bold.eot');
  src: local('Graphik Bold'), local('Graphik-Bold'),
      url('./assets/fonts/Graphik-Bold.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Graphik-Bold.woff2') format('woff2'),
      url('./assets/fonts/Graphik-Bold.woff') format('woff'),
      url('./assets/fonts/graphik_lc_700.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik Medium';
  src: url('./assets/fonts/Graphik-Regular.eot');
  src: local('Graphik Regular'), local('Graphik-Regular'),
      url('./assets/fonts/Graphik-Regular.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Graphik-Regular.woff2') format('woff2'),
      url('./assets/fonts/Graphik-Regular.woff') format('woff'),
      url('./assets/fonts/graphik_lc_400.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('./assets/fonts/Graphik-BoldItalic.eot');
  src: local('Graphik Bold Italic'), local('Graphik-BoldItalic'),
      url('./assets/fonts/Graphik-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Graphik-BoldItalic.woff2') format('woff2'),
      url('./assets/fonts/Graphik-BoldItalic.woff') format('woff'),
      url('./assets/fonts/Graphik-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik';
  src: url('./assets/fonts/Graphik-SemiboldItalic.eot');
  src: local('Graphik Semibold Italic'), local('Graphik-SemiboldItalic'),
      url('./assets/fonts/Graphik-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Graphik-SemiboldItalic.woff2') format('woff2'),
      url('./assets/fonts/Graphik-SemiboldItalic.woff') format('woff'),
      url('./assets/fonts/Graphik-SemiboldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik';
  src: url('./assets/fonts/Graphik-LightItalic.eot');
  src: local('Graphik Light Italic'), local('Graphik-LightItalic'),
      url('./assets/fonts/Graphik-LightItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Graphik-LightItalic.woff2') format('woff2'),
      url('./assets/fonts/Graphik-LightItalic.woff') format('woff'),
      url('./assets/fonts/Graphik-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik';
  src: url('./assets/fonts/Graphik-Extralight.eot');
  src: local('Graphik Extralight'), local('Graphik-Extralight'),
      url('./assets/fonts/Graphik-Extralight.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Graphik-Extralight.woff2') format('woff2'),
      url('./assets/fonts/Graphik-Extralight.woff') format('woff'),
      url('./assets/fonts/Graphik-Extralight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('./assets/fonts/Graphik-MediumItalic.eot');
  src: local('Graphik Medium Italic'), local('Graphik-MediumItalic'),
      url('./assets/fonts/Graphik-MediumItalic.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/Graphik-MediumItalic.woff2') format('woff2'),
      url('./assets/fonts/Graphik-MediumItalic.woff') format('woff'),
      url('./assets/fonts/Graphik-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}
