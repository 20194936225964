body {
  background: url('./assets/bg.png') no-repeat center center fixed;
  background-size: cover;
  text-rendering: optimizeLegibility;
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  body {
    background: none;
  }
}

div {
  display: block;
}

*, ::after, ::before {
  box-sizing: border-box;
}

button, a {
  outline: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  background: none;
  box-shadow: none;
  user-select: none;
  -webkit-user-select: none;
}

button, a {
  touch-action: manipulation;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  height: 100dvh;
  height: 100svh;
  max-height: -webkit-fill-available;
  width: 100%;
}

.container {
  width: 58vh;
  width: 58dvh;
  width: 58svh;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #E1EAEF;
  perspective: 1000px;
  box-sizing: border-box;
}

header {
  width: 100%;
  overflow-x: clip;
}

.noise {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.noise-bg {
  height: 100%;
  width: 100%;
  position: absolute;
  background: url('./assets/noise.png') no-repeat center;
  background-size: cover;
  display: block;
  box-sizing: border-box;
  z-index: 2;
}

.plain {
  width: 100%;
  background: #10181C;
  display: flex;
  box-shadow: 0 4px 20px 0px #030506;
  z-index: 1;
}

.circles {
  width: 100%;
  background: url('./assets/bg_header_circles.png') no-repeat center;
  background-size: cover;
  background-position: center bottom;
  box-sizing: border-box;
  display: flex;
  box-shadow: 0px 5px 13px 0px #638497;
}

.plain .logo-ya-bm {
  max-width: 50%;
  margin: 0 auto;
  padding: 7.5px 0;
  display: flex;
  z-index: 3;
}

.circles .logo-title {
  max-width: 35%;
  margin: 0 auto;
  padding: 10px 0 20px 0;
  display: flex;
  z-index: 3;
}

.content {
  flex: 1;
  padding: 0 5vh;
  padding: 0 5dvh;
  padding: 0 5svh;
  flex-direction: column;
  align-items: center;
  display: flex;
  line-height: 1.4;
}

.content p {
  flex: 1;
  display: flex;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  text-rendering: optimizelegibility;
  user-select: none;
  font-family: 'Kazimir';
  font-style: normal;
  font-size: calc(6px + 20*(100vh - 320px) / 680);
  font-size: calc(6px + 20*(100dvh - 320px) / 680);
  font-size: calc(6px + 20*(100svh - 320px) / 680);
  font-weight: 500;
  line-height: 114%;
  color: #1A282F;
}

.wrapper {
  width: 50vh;
  height: 50vh;
  width: 50dvh;
  height: 50dvh;
  width: 50svh;
  height: 50svh;
  position: relative;
  flex-shrink: 0;
}

footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding: 10px 0 0 0;
  box-sizing: border-box;
  background-color: #E1EAEF;
}

.footer-element {
  padding: 0px 4.5px;
  text-align: center;
  height: 40px;
}

.footer-element svg {
  width: calc(26px + 20*(100vh - 320px) / 680);
  width: calc(26px + 20*(100dvh - 320px) / 680);
  width: calc(26px + 20*(100svh - 320px) / 680);
}

.footer-element h2 {
  font-size: calc(14px + 20*(100vh - 320px) / 680);
  font-size: calc(14px + 20*(100dvh - 320px) / 680);
  font-size: calc(14px + 20*(100svh - 320px) / 680);
  font-weight: 500;
  color: #1A282F;
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .container {
    width: 100%;
    max-width: none;
  }
}

@media (max-aspect-ratio: 29 / 50) {
  .wrapper {
    width: 85vw;
    height: 85vw;
  }
}

@media only screen and (max-width: 375px) and (orientation: portrait) {
  .wrapper {
    width: 85vw;
    height: 85vw;
  }

  .circles .logo-title {
    padding: 7.5px 0 10px 0;
  }

  .content p {
    margin: 5px;
  }

  .footer-element {
    height: 30px;
  }
}
